import React from 'react';
import Layout from '../layouts/index';
import BookImage from '../images/book-sentencePatterns1.png';
import { ReactPhotoCollage } from 'react-photo-collage';
import Recommended from '../components/Recommended';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import Link from 'gatsby-link';
import LazyIframe from '../components/LazyIframe';
import Payhip from '../components/Payhip';

class CourseDetails extends React.Component {
	render() {
		const setting = {
			width: '100%',
			height: ['500px', '170px'],
			layout: [1, 4],
			photos: [
				{
					source:
						'https://raw.githubusercontent.com/Caroline-Phung/HowToVietnameseAssets/master/Assets/patterns-book1-preview/book1-page1.png',
				},
				{
					source:
						'https://raw.githubusercontent.com/Caroline-Phung/HowToVietnameseAssets/master/Assets/patterns-book1-preview/book1-page2.png',
				},
				{
					source:
						'https://raw.githubusercontent.com/Caroline-Phung/HowToVietnameseAssets/master/Assets/patterns-book1-preview/book1-page3.png',
				},
				{
					source:
						'https://raw.githubusercontent.com/Caroline-Phung/HowToVietnameseAssets/master/Assets/patterns-book1-preview/book1-page4.png',
				},
				{
					source:
						'https://raw.githubusercontent.com/Caroline-Phung/HowToVietnameseAssets/master/Assets/patterns-book1-preview/book1-page5.png',
				},
			],
			showNumOfRemainingPhotos: true,
		};
		return (
			<Layout>
				{/* Course info */}
				<div className="container-fluid margin-from-header bg-white">
					<div className="container pt-4 pt-md-8 pb-8">
						<div className="row d-flex justify-content-md-center reverse-flex-mobile">
							<div className="col-sm-12 col-md-7 d-flex flex-column justify-content-left ">
								<h2>
									Survival Vietnamese: Speak Instantly Without Fear (Video
									course)
								</h2>
								<p>
									Perfect for an absolute beginner who wants to start learning
									Vietnamese with the most practical phrases for daily needs
									communication.
								</p>
								<p>
									Level: <b>Absolute beginner</b>
								</p>

								<div className="row pl-1 mt-2">
									{/* <p className="text-primary">Enrollment is closed</p> */}
									<Payhip productID="vgf8J" label="Buy now - $19.99"></Payhip>
									{/* <OutboundLink
                    class="btn-simple btn-primary-outline"
                    // href="https://gum.co/suojc?wanted=true"
                  >
                    Get now for $19
                  </OutboundLink> */}
								</div>
							</div>
							{/* <div className="col-12 col-md-0 mb-2">
                <img
                src={BookImage}
                className="img-fluid float-right rounded"
                alt="Vietnamese Basic Grammar and Sentence Patterns Book 1"
                ></img>
              </div> */}
						</div>
					</div>
				</div>
				{/* Course contents */}
				<div className="container pt-md-10 pb-md-10 pt-6 pb-6">
					<div className="row justify-content-md-center">
						<div className="col-sm-12 col-md-8 ml-1 mr-1">
							<iframe
								className="video-iframe"
								src="https://player.vimeo.com/video/673878713?h=6fd4c74ffd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
								frameborder="0"
								height="420"
								width="100%"
								allow="autoplay; fullscreen; picture-in-picture"
								allowfullscreen
								title="Introduction: Survival Vietnamese Course"
							></iframe>
							<script src="https://player.vimeo.com/api/player.js"></script>
							<h2 className="mt-8">What will you achieve?</h2>
							<ul>
								<li>
									Feel <b>comfortable communicating</b> in Vietnamese with the
									locals for your daily needs - getting a taxi, ordering food &
									drinks, shopping, etc.
								</li>
								<li>
									Learn only the <b>most essential and practical</b> Vietnamese
									phrases that you'll need to get you speaking after a short
									period of time.
								</li>
								<li>
									Develop your <b>listening skill</b> and{' '}
									<b>acquire good pronunciation</b> through audio dialogue
									listening practices and animated tone marks.
								</li>
							</ul>

							<h2 className="mt-8">Why this course?</h2>
							<ol className="pl-0">
								<li>
									<b>
										<u>Bite-sized lessons:</u>
									</b>{' '}
									There are 15 bite-sized core video lessons, easily digestible
									but that are packed with knowledge and no fluff, so you can
									re-watch them as many times as you want.
								</li>
								<li>
									<b>
										<u>Stimulating features:</u>
									</b>{' '}
									Images for visual aids, animated tones, and color code for
									clear grammar explanation, all are used to make sure that each
									lesson is easy and clear to understand for beginners.
								</li>
								<li>
									<b>
										<u>Focused practices:</u>
									</b>{' '}
									There are listening & pronunciation practices at the end of
									each topic, using audio dialogue that is similar to real-life
									situations, to help you feel more confident using your
									language skills.
								</li>
								<li>
									<b>
										<u>Clear goals & progress:</u>
									</b>{' '}
									A course checklist is provided so you'll know exactly what
									your goals are, and track what you've learned for each lesson.
									After ticking off each task, you'll also feel a great sense of
									progress and be motivated to learn more!
								</li>
							</ol>
							<h2 className="pt-8">Lesson Preview</h2>
							<div>
								<iframe
									className="video-iframe"
									src="https://player.vimeo.com/video/673894430?h=6fd4c74ffd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
									frameborder="0"
									height="420"
									width="100%"
									allow="autoplay; fullscreen; picture-in-picture"
									allowfullscreen
									title="Introduction: Survival Vietnamese Course"
								></iframe>
								<script src="https://player.vimeo.com/api/player.js"></script>
							</div>
							<h2 className="pt-8">Contents</h2>
							<p>
								<b>Topic 1: Chatting</b>
							</p>
							<ul>
								<li>Lesson 1: Basic Expressions</li>
								<li>Lesson 2: Addressing People</li>
								<li>Lesson 3: Introducing Yourself</li>
								<li>
									Lesson 4: Language Difficulty -{' '}
									<OutboundLink
										class="text-decoration-underline"
										href="https://vimeo.com/673894430"
									>
										Preview lesson
									</OutboundLink>
								</li>
							</ul>

							<p>
								<b>Topic 2: Shopping</b>
							</p>
							<ul>
								<li>Lesson 5: Going Shopping (part 1)</li>
								<li>Lesson 6: Going Shopping (part 2)</li>
								<li>Lesson 7: Making Payment</li>
								<li>
									Dialogue 1: Shopping -{' '}
									<OutboundLink
										class="text-decoration-underline"
										href="https://vimeo.com/695948858"
									>
										Preview lesson
									</OutboundLink>
								</li>
							</ul>

							<p>
								<b>Topic 3: Getting Around</b>
							</p>
							<ul>
								<li>Lesson 8: Asking for Location</li>
								<li>Lesson 9: Using Transportation</li>
								<li>Dialogue 2: Getting a Taxi</li>
							</ul>

							<p>
								<b>Topic 4: Eating & Drinking</b>
							</p>
							<ul>
								<li>Lesson 10: Ordering at a Cafe</li>
								<li>Dialogue 3: Ordering a Drink</li>
								<li>Lesson 11: Eating at a Restaurant</li>
								<li>Lesson 12: Expressing Food Preferences</li>
							</ul>

							<p>
								<b>Topic 5: Accommodation</b>
							</p>
							<ul>
								<li>Lesson 13: Checking-In/Out</li>
								<li>Lesson 14: Requesting Services</li>
								<li>Dialogue 4: At the Homestay</li>
							</ul>

							<p>
								<b>Topic 6: Health</b>
							</p>
							<ul>
								<li>Lesson 15: Not Feeling Well</li>
							</ul>

							<h2 className="pt-8">What's included?</h2>
							<ul>
								<li>Bite-sized Lessons & Practice - 20 videos</li>
								<li>Course Checklist - 1 PDF file</li>
								<li>Numbers Cheatsheet - 1 PDF file</li>
							</ul>
						</div>
					</div>
				</div>
				<Recommended
					recommended={[
						'/vietnamese-ebook-bundle',
						'/vietnamese-grammar-book-bundle',
					]}
				/>
			</Layout>
		);
	}
}

export default CourseDetails;
